(function($){
    jQuery(window).on("load", function () {
        var w = $(window).width();
        if (w > 1199) {
            $('#treatment-1').addClass('active');
            $('#treatment-1').children('.image').fadeIn();

            let count = 1 ;
            setInterval(function () {
                count++;
                if (count > 4) {
                    count = 1;
                }
                let currentLi = '#treatment-' + count;
                let lastCount = count - 1;
                if (lastCount < 1) {
                    lastCount = 4;
                }
                let lastLi = '#treatment-' + lastCount;
                $(lastLi).removeClass('active');
                $(currentLi).addClass('active');
                $(lastLi).children('.image').fadeOut();
                $(currentLi).children('.image').fadeIn();
            }, 3000);

            let lastScroll = 0;
            window.addEventListener("scroll", () => {
                let currentScroll = window.pageYOffset;
                if (currentScroll - lastScroll > 0) {
                    if (currentScroll > 50) {
                        $('header .nav-bar .row').height(80);
                        $('header .nav-bar .logo img').width(200);
                    }
                }
                lastScroll = currentScroll;
            });
        }
    });

    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        // Inline label forms: Add active class if input has value
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').each(function () {
            if ($(this).val().length) {
                $(this).closest('.gfield').addClass('active');
            }
        });

        $('.js-gform select').on('change', function() {
            if ($(this).val() === "") {
                $(this).closest('.gfield').removeClass('active');
            } else {
                $(this).closest('.gfield').addClass('active');
            }
        });

        // Inline label forms: Add active class on focus
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('focus', function () {
            $(this).closest('.gfield').addClass('active');
        });

        // Inline label forms: Remove active class on blur
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number]').on('blur', function () {
            if (!$(this).val().length || $(this).val().includes('_')) {
                $(this).closest('.gfield').removeClass('active');
            }
        });

        $('.history-slider').not('.slick-initialized').slick({
            speed: 300,
            prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/estimates/dist/images/arrow-previous.png" alt="Previous" /></button>',
		    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/estimates/dist/images/arrow-next.png" alt="Next" /></button>',
        });

        $('.reviews-slider').not('.slick-initialized').slick({
            responsive: [
                {
                    breakpoint: 2400,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ],
            speed: 300,
            prevArrow: '<button type="button" class="slick-prev" aria-label="Previous"><img src="/wp-content/themes/estimates/dist/images/arrow-previous-gray.png" alt="Previous" /></button>',
		    nextArrow: '<button type="button" class="slick-next" aria-label="Next"><img src="/wp-content/themes/estimates/dist/images/arrow-next-gray.png" alt="Next" /></button>',
        });
    });

    // Pop up actions
    $('.js-popup').on('click', function(e) {
        e.preventDefault();
        $('#popup').css('display', 'flex');
    });

    $('.js-close-popup').on('click', function(e) {
        e.preventDefault();
        $('#popup').css('display', 'none');
    });
    
})(jQuery);